<template>
    <div class="footer-bar">
        <div class="footer-content">
            <div class="footer-menu">
                <span class="menu-title" @click="handleJump('/home')">首页</span>
                <span class="menu-title" @click="handleJump('/business')">关于我们</span>
                <span class="menu-title" @click="handleJump('/production')">解决方案</span>
                <span class="menu-title" @click="handleJump('/industry')">行业动态</span>
                <span class="menu-title" @click="handleJump('/contact')">联系我们</span>
            </div>
            <div class="footer-img">
                <img src="~@/assets/footer.webp" alt="">
            </div>
            <div class="intellicom">
                CLZYTECH
            </div>
            <div class="copyright">
                ©2023 成理智源 版权所有
            </div>
            <div class="copyright">
                <a href="https://beian.miit.gov.cn/" class="fill">蜀ICP备19031505号-1</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  methods: {
    handleJump (val) {
      const path = this.$router.currentRoute.path
      if (path !== val) {
        this.$router.push({
          path: val
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.footer-bar {
    width: 100%;
    height: 14rem;
    background: rgb(22, 22, 22);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666;

    .footer-content {
        width: 60.6%;
        height: 12.5714rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;

        .footer-menu {
            width: 100%;
            height: 2.8571rem;
            display: flex;
            justify-content: center;
            margin-top: .5rem;
            align-items: center;

            .menu-title {
                transition: all 0.3s;
                cursor: pointer;
                &:hover{
                    color: #fff;
                    transition: all 0.3s;
                }
            }

            .menu-title:not(:first-child) {
                margin-left: 3.0714rem;
            }
        }

        .footer-img {
            width: 100%;
            padding-top: .5rem;
            height: 1.4286rem;
        }

        .intellicom {
            width: 100%;
            height: 2.8571rem;
            margin-top: .5rem;
            font-size: 2rem;
            color: #076ce0;
            font-weight: 600;

        }

        .copyright,
        .filings {
            width: 100%;
            height: 1.5714rem;
            line-height: 1.5714rem;
            font-size: .8571rem;
            .fill{
                font-size: .9286rem;
                font-weight: bold;
                color: #666;
                &:hover{
                    color: #fff;
                }
            }
        }

        .filings {
            .filings-title {
                cursor: pointer;

                &:hover {
                    color: #fff;
                }
            }

            .pad-3 {
                padding: 0 .2143rem;
            }
        }
    }
}
</style>
