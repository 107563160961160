<template>
  <div class="menu">
    <div class="logo">
      <img src="~@/assets/logo.png" alt="" class="logoPic" />
    </div>
    <div class="nav">
      <Menu mode="horizontal" :active-name="activeName" @on-select="handleSelect">
        <template v-for="(item, index) in pathList">
          <MenuItem :name="item.path" :key="index" v-if="!item.children.length">
          {{ item.name }}
          </MenuItem>
          <Submenu :key="item.path" :name="item.path" v-else>
            <template slot="title">
              {{ item.name }}
            </template>
            <MenuItem :name="temp.path" v-for="(temp, indexs) in item.children" :key="indexs">{{ temp.name }}</MenuItem>
          </Submenu>
        </template>
      </Menu>
    </div>
  </div>
</template>

<script>
export default {
  computed: {},
  data () {
    return {
      activeName: '/home',
      pathList: [
        {
          name: '首页',
          path: '/home',
          id: 1,
          children: []
        },
        {
          name: '成理极联',
          path: '/cljl',
          id: 111,
          children: []
        },
        {
          name: '产品服务',
          path: '/productService',
          id: 2,
          children: [
            {
              name: '感知设备',
              path: '/hardware',
              id: '2-1'
            },
            {
              name: '行业软件',
              path: '/software',
              id: '2-2'
            },
            {
              name: '定制开发',
              path: '/customDevelopment',
              id: '2-5'
            }
          ]
        },
        {
          name: '解决方案',
          path: '/production',
          id: 3,
          children: []
        },
        {
          name: '服务与支持',
          path: '/serviceSupport',
          id: 4,
          children: [
            {
              name: '支持',
              path: '/support',
              id: '4-2'
            },
            {
              name: '培训',
              path: '/serviceClass',
              id: '4-2'
            },
            {
              name: '服务网点',
              path: '/serviceNetwork',
              id: '4-4'
            }
          ]
        },
        {
          name: '资讯动态',
          path: '/infoDynamics',
          id: 5,
          children: [
            {
              name: '公司新闻',
              path: '/companyNews',
              id: '5-1'
            },
            {
              name: '行业动态',
              path: '/industry',
              id: '5-2'
            }
          ]
        },
        {
          name: '招贤纳士',
          path: '/recruit',
          id: 6,
          children: [
            {
              name: '公司环境',
              path: '/companyEnv',
              id: '6-1'
            },
            {
              name: '人才招聘',
              path: '/talentRecruit',
              id: '6-2'
            }
          ]
        },
        {
          name: '关于我们',
          path: '/business',
          id: 7,
          children: []
        }
      ]
    }
  },
  mounted () {
  },
  watch: { // 监听，当路由发生变化的时候执行
    $route (to, from) {
      this.activeName = to.fullPath
    }
  },
  methods: {
    handleSelect (name) {
      if (name === '/helpDocument') {
        window.location.href = 'https://docs.clzytech.com/'
        return
      }else if  (name === '/cljl') {
        window.location.href = 'https://geoink.clzytech.com:1024/cart/'
        return
      }
      if (this.$route.path !== name) {
        this.$router.push({
          path: name
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.menu {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1300px;
  .logo {
    height: 4.8rem;
    width: 17.4286rem;

    .logoPic {
      width: 100%;
      height: 100%;
    }
  }

  .nav {
    width: 60%;
    margin-left: 3rem;
    height: 3.5714rem;
    display: flex;
    justify-content: space-around;
    .ivu-menu-light:after{
      background: transparent;
    }

    .active {
      color: #076ce0 !important;
      border-bottom: 2px solid #076ce0 !important;
    }

    .menuItem {
      height: 3.5714rem;
      display: flex;
      padding: 0 1rem;
      font-size: 1.0714rem;
      align-items: center;
      border-bottom: 2px solid transparent;
      color: #000;
      border-bottom: 2px solid transparent;

      // &.router-link-exact-active {
      //       color: #076ce0;
      //       border-bottom: 2px solid #076ce0;
      //     }
      &:hover {
        color: #076ce0;
        border-bottom: 2px solid #076ce0;
      }
    }
  }
}
</style>
