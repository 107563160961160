<template>
    <div class="layout">
        <div class="menuBar">
            <MenuBar></MenuBar>
        </div>
        <div class="contentBar">
            <keep-alive :include="keepAlive">
            <router-view />
            </keep-alive>
        </div>
        <footerBar></footerBar>
        <BackTop :height="100" :bottom="50" :right="57">
            <img src="~@/assets/top.png" alt="">
        </BackTop>
    </div>
</template>

<script>
import MenuBar from './menuBar/menuBar'
import FooterBar from './footerBar/footerBar'

export default {
  components: {
    MenuBar,
    FooterBar
  },
  data () {
    return {
      keepAlive: ''
    }
  },
  mounted () {
    this.keepAlive = this.$route.path
  }
}
</script>

<style lang="less" scoped>
.layout {
    width: 100%;
    height: 100vh;

    .menuBar {
        width: 100%;
        height: 8rem;
        position: fixed;
        z-index: 99;
        top: 0;
        background: #fff;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0 .7143rem .4286rem -0.4286rem rgba(0, 0, 0, 0.05);
    }

    .contentBar {
        width: 100%;
        min-height: ~'calc(100% - 22rem)';
        overflow: auto;
        margin-top: 8rem;
    }
}
</style>
