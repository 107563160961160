import Vue from 'vue'
import VueRouter from 'vue-router'
import BasicLayout from '@/layout/basic-layout/basicLayout.vue'
import EmptyLayout from '@/layout/empty-layout/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'BasicLayout',
    component: BasicLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'hone',
        show: true,
        component: () => import('@/pages/home/index.vue')
      },
      {
        path: '/cljl',
        name: '成理极联',
        component: () => import('@/pages/production/index.vue')
      },
      {
        path: '/productService',
        name: '产品服务',
        component: EmptyLayout,
        children: [{
          path: '/hardware',
          name: '硬件',
          show: true,
          component: () => import('@/pages/productService/hardware/index.vue')
        },
        {
          path: '/software',
          name: '软件',
          show: true,
          component: () => import('@/pages/productService/software/index.vue')
        },
        {
          path: '/bigData',
          name: '大数据中台',
          show: true,
          component: () => import('@/pages/productService/bigData/index.vue')
        },
        {
          path: '/serviceClass',
          name: '培训',
          show: true,
          component: () => import('@/pages/productService/serviceClass/index.vue')
        },
        {
          path: '/customDevelopment',
          name: '定制开发',
          show: true,
          component: () => import('@/pages/productService/customDevelopment/index.vue')
        }]
      },
      // 产品服务=====
      // 软件详情=====
      {
        path: '/universalDetail',
        name: '普适性地质灾害监测预警平台',
        show: true,
        component: () => import('@/pages/productService/software/detail/universalDetail.vue')
      },
      {
        path: '/trafficDetail',
        name: '交通自然灾害监测平台',
        show: true,
        component: () => import('@/pages/productService/software/detail/trafficDetail.vue')
      },
      {
        path: '/iotPlatform',
        name: '物联网平台',
        show: true,
        component: () => import('@/pages/productService/software/detail/iotPlatform.vue')
      },
      {
        path: '/engineeringDetail',
        name: '工程结构安全监测平台',
        show: true,
        component: () => import('@/pages/productService/software/detail/engineeringDetail.vue')
      },
      {
        path: '/foreignMatterDetail',
        name: '异物入侵检测平台',
        show: true,
        component: () => import('@/pages/productService/software/detail/foreignMatterDetail.vue')
      },
      {
        path: '/emergencyrDetail',
        name: '应急救援与指挥调度平台',
        show: true,
        component: () => import('@/pages/productService/software/detail/emergencyrDetail.vue')
      },
      {
        path: '/riskDetail',
        name: '风险普查成果数据汇交系统',
        show: true,
        component: () => import('@/pages/productService/software/detail/riskDetail.vue')
      },
      {
        path: '/remoteSenseDetail',
        name: '遥感智能解译平台',
        show: true,
        component: () => import('@/pages/productService/software/detail/remoteSenseDetail.vue')
      },
      // 软件详情===== end
      {
        path: '/hardwareDetail',
        name: 'hardwareDetail',
        show: true,
        component: () => import('@/pages/productService/hardware/detail.vue')
      },
      // 大数据中台 ===== start
      {
        path: '/aiApplicationCenterDetail',
        name: 'Ai应用中心',
        show: true,
        component: () => import('@/pages/productService/bigData/detail/aiApplicationCenterDetail.vue')
      },
      {
        path: '/aiotDetail',
        name: 'AIOT中心',
        show: true,
        component: () => import('@/pages/productService/bigData/detail/aiotDetail.vue')
      },
      {
        path: '/geoCenterDetail',
        name: '地理空间中心',
        show: true,
        component: () => import('@/pages/productService/bigData/detail/geoCenterDetail.vue')
      },
      {
        path: '/warnCenterDetail',
        name: '告警中心',
        show: true,
        component: () => import('@/pages/productService/bigData/detail/warnCenterDetail.vue')
      },
      {
        path: '/videoCenterDetail',
        name: '视频中心',
        show: true,
        component: () => import('@/pages/productService/bigData/detail/videoCenterDetail.vue')
      },
      {
        path: '/remoteSensingCenterDetail',
        name: '遥感中心',
        show: true,
        component: () => import('@/pages/productService/bigData/detail/remoteSensingCenterDetail.vue')
      },
      {
        path: '/droneCenterDetail',
        name: '无人机中心',
        show: true,
        component: () => import('@/pages/productService/bigData/detail/droneCenterDetail.vue')
      },
      // 大数据中台===== end
      // 产品服务===== end
      // 解决方案
      {
        path: '/production',
        name: '解决方案',
        component: () => import('@/pages/production/index.vue')
      },
      {
        path: '/productionDetail',
        name: 'productionDetail',
        show: true,
        component: () => import('@/pages/production/detail/index.vue')
      },
      // 解决方案 ====end
      {
        path: '/companyNewslist',
        name: 'companylist',
        show: true,
        component: () => import('@/pages/companyNews/list/index.vue')
      },
      {
        path: '/companyNewsdetail',
        name: 'companydetail',
        show: true,
        component: () => import('@/pages/companyNews/detail/index.vue')
      },
      {
        path: '/productDetail',
        name: 'productDetail',
        show: true,
        component: () => import('@/pages/production/detail/old.vue')
      },
      {
        path: '/business',
        name: 'business',
        show: true,
        component: () => import('@/pages/business/index.vue')
      },
      {
        path: '/projectDetail',
        name: 'projectDetail',
        show: true,
        component: () => import('@/pages/production/detail/detail.vue')
      },
      {
        path: '/serviceSupport',
        name: '服务与支持',
        component: EmptyLayout,
        children: [{
          path: '/helpDocument',
          name: '帮助文档',
          show: true,
          component: () => import('@/pages/serviceSupport/helpDocument/index.vue')
        },
        {
          path: '/support',
          name: '支持',
          show: true,
          component: () => import('@/pages/serviceSupport/support/index.vue')
        },
        {
          path: '/serviceNetwork',
          name: '服务网点',
          show: true,
          component: () => import('@/pages/serviceSupport/serviceNetwork/index.vue')
        }]
      },
      {
        path: '/infoDynamics',
        name: '资讯动态',
        component: EmptyLayout,
        children: [{
          path: '/companyNews',
          name: '公司新闻',
          show: true,
          component: () => import('@/pages/infoDynamics/companyNews/index.vue')
        },
        {
          path: '/industry',
          name: '行业动态',
          show: true,
          component: () => import('@/pages/infoDynamics/industry/index.vue')
        }]
      },
      {
        path: '/recruit',
        name: '招贤纳士',
        component: EmptyLayout,
        children: [{
          path: '/companyEnv',
          name: '公司环境',
          show: true,
          component: () => import('@/pages/recruit/companyEnv/index.vue')
        },
        {
          path: '/talentRecruit',
          name: '人才招聘',
          show: true,
          component: () => import('@/pages/recruit/talentRecruit/index.vue')
        }]
      },
      {
        path: '/industryDetail',
        name: 'industryDetail',
        show: true,
        component: () => import('@/pages/industry/detail/index.vue')
      },
      {
        path: '/humanPower',
        name: 'humanPower',
        show: true,
        component: () => import('@/pages/humanPower/index.vue')
      },
      {
        path: '/contact',
        name: 'contact',
        show: true,
        component: () => import('@/pages/contact/index.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
