<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { on, off } from 'view-design/src/utils/dom'
import { setMatchMedia } from 'view-design/src/utils/assist'
setMatchMedia()
export default {
  name: 'app',
  methods: {
    handleWindowResize () {
      this.setRootFontSize()
    },
    setRootFontSize () {
      const width = window.innerWidth
      const size = 100 / 1920 * width
      let fontsize = size / (100 / 14)
      if (fontsize <= 8) {
        fontsize = 8
      }
      if (fontsize >= 26) {
        fontsize = 26
      }
      document.documentElement.style.fontSize = fontsize + 'px'
      document.body.style.fontSize = fontsize + 'px'
    }
  },
  watch: {
    themStyle: {
      handler (val) {
        document.body.className = val
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    on(window, 'resize', this.handleWindowResize)
    this.setRootFontSize()
  },
  beforeDestroy () {
    off(window, 'resize', this.handleWindowResize)
  }
}
</script>

<style lang="less">
    .vertical-center-modal{
        display: flex;
        align-items: center;
        justify-content: center;

        .ivu-modal{
            top: 0;
        }
    }
</style>
