<template>
    <div class="empty-layout">
        <keep-alive :include="keepAlive">
            <router-view v-if="loadRouter" />
        </keep-alive>
    </div>
</template>

<script>
export default {
  name: 'EmptyLayout',
  computed: {
  },
  data () {
    return {
      keepAlive: '',
      loadRouter: true
    }
  },
  mounted () {
    this.keepAlive = this.$route.path
  }
}
</script>

<style lang="less"></style>
